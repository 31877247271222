<template>
  <div class="flex flex-col justify-start mx-5 desktop:mx-10 my-5">
    <div class="flex">
      <img
        v-if="auth.user != null"
        :src="bugtrack.photo"
        :alt="bugtrack.author"
        class="h-12 w-12 object-cover mr-4 transition-all duration-150 ease-in-out rounded-full ring-1 ring-drc-grau-400"
      />
      <div class="flex-grow flex flex-col">
        <div class="flex justify-start w-full px-3 py-2">
          <div class="text-drc-gruen-300 text-sm font-bold mr-4">
            {{ bugtrack.author }}
          </div>
          <div class="text-drc-grau-300 text-sm">{{ bugtrack.created_at }}</div>
          <div
            v-if="
              bugtrack.created_at != bugtrack.updated_at &&
              (bugtrack.updated_at != '' || bugtrack.updated_at != null)
            "
            class="text-drc-grau-300 text-sm italic"
          >
            &nbsp;&nbsp;(bearbeitet: {{ bugtrack.updated_at }})
          </div>
        </div>
        <div class="flex items-start">
          <div class="flex flex-col justify-start items-start w-full">
            <b-textarea-autoresize
              class="text-sm h-24 w-full px-3 py-2 text-gray-700 rounded focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb"
              :class="{ 'bg-white text-black': disabled }"
              placeholder="Neue Nachricht eingeben"
              id="newnotice"
              name="newnotice"
              :disabled="disabled"
              :value="bugtrack.text"
              @update:value="
                bugtrack.text = $event;
                changed = true;
              "
              @focus="focus = true"
              min-height="2"
              max-height="1000"
            ></b-textarea-autoresize>
          </div>
          <button
            class="bg-drc-gruen-300 text-white w-20 ml-2 text-sm hover:text-drc-orange border"
            :class="{ '!bg-drc-marker-rot': !bugtrack.closed }"
            v-if="!disabled"
            @click="toggleClose()"
          >
            <span v-if="bugtrack.closed">erledigt</span>
            <span v-else="bugtrack.closed">offen</span>
          </button>
          <a
            v-if="bugtrack.url"
            class="text-sm bg-drc-gruen-300 text-white w-20 ml-2 text-sm hover:text-drc-orange border"
            :href="bugtrack.url"
            target="_blank"
            >Link</a
          >
          <button
            class="text-drc-gruen-300 w-10 ml-2 text-sm hover:text-drc-orange"
            v-if="!disabled"
            @click="del()"
          >
            <svg-loeschen class="w-6 h-6" />
          </button>
        </div>
        <button
          class="text-white bg-drc-gruen-300 w-28 rounded-lg mt-1 text-sm hover:bg-drc-orange"
          v-if="!disabled && changed"
          @click="update()"
        >
          Speichern
        </button>
      </div>
    </div>
    <!-- <div class="px-3 py-3 mb-10 text-gray-700 rounded min-w-75 bg-white text-sm text-left" v-html="divtext" /> -->
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useHundStore } from "@/stores/hund";
import { storeToRefs } from "pinia";

import BButton from "@/components/global/buttons/BButton.vue";
import BCheckboxSolo from "@/components/global/checkboxes/BCheckboxSolo.vue";
import BTextareaAutoresize from "@/components/global/inputs/BTextareaAutoresize.vue";
import BInput from "@/components/global/inputs/BInput.vue";

const auth = useAuthStore();

const emits = defineEmits(["delete"]);

const props = defineProps({
  index: {
    type: Number,
  },
  id: {
    type: Number,
  },
  bugtrack: {
    type: Object,
  },
});

const focus = ref(false);
const changed = ref(false);

const disabled = computed(() => {
  return props.bugtrack.user_id == auth.user.id ? false : true;
});

const divtext = computed(() => {
  if (props.bugtrack.text)
    return props.bugtrack.text
      .replace(/\r?\n/g, "<br/>")
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
});

function update(e) {
  axios.put("api/bugtrack/" + props.bugtrack.id, props.bugtrack).then((response) => {
    console.log(response.data);
    if (response.data.bugtrack.id) {
      props.bugtrack.updated_at = response.data.bugtrack.updated_at;
      changed.value = false;
      //    id: response.data.bugtrack.id,
      // })
      // newNotiz.value = ''
      // snackbar.add({ type: 'success', text: response.data.success })
    }
  });
}

function del(e) {
  axios.delete("api/bugtrack/" + props.bugtrack.id).then((response) => {
    props.bugtrack.text = "gelöscht";
    emits("delete");

    if (response.data.bugtrack.id) {
      props.bugtrack.text = "gelöscht";
      //  emit('delete')
      //    id: response.data.bugtrack.id,
      // })
      // newNotiz.value = ''
      // snackbar.add({ type: 'success', text: response.data.success })
    }
  });
}

function toggleClose(e) {
  props.bugtrack.closed = !props.bugtrack.closed;
  axios.put("api/bugtrack/" + props.bugtrack.id, props.bugtrack).then((response) => {
    console.log(response.data);
    if (response.data.bugtrack.id) {
      props.bugtrack.updated_at = response.data.bugtrack.updated_at;
      changed.value = false;
      //    id: response.data.bugtrack.id,
      // })
      // newNotiz.value = ''
      // snackbar.add({ type: 'success', text: response.data.success })
    }
  });
}
</script>

<style lang="scss"></style>
