import { createApp , ref } from 'vue'
import App from './App.vue'
import router from './router'

import localForage from "localforage";
localForage.config({
   driver: localForage.INDEXEDDB, // This force IndexedDB as the driver
})

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


import './helpers/date2german'
import './assets/css/tailwind.css'
// import './assets/scss/sweetalert2/sweetalert2.scss'

import ClickOutside from '@/components/global/helpers/ClickOutsideDirective'

import axios from 'axios'
window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = 'http://localhost:8000'

const app = createApp(App).use(router).use(pinia)

import { useGlobalStore } from '@/stores/global'
import { useAuthStore } from '@/stores/auth'
const global = useGlobalStore()
const auth = useAuthStore()

var app_mode = import.meta.env.MODE

console.log('env', import.meta.env)


import Pusher from 'pusher-js'
window.Pusher = Pusher

import Echo from 'laravel-echo'
window.Echo = new Echo({
  broadcaster: 'reverb',
  key: import.meta.env.VITE_REVERB_APP_KEY,
  wsHost: import.meta.env.VITE_REVERB_HOST,
  wsPort: import.meta.env.VITE_REVERB_PORT,
  wssPort: import.meta.env.VITE_REVERB_PORT,
  forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
})
// app_mode = 'development';
switch (app_mode) {
   /** LIVE **/
   case 'production':
      app.provide('$_COOKIEDOMAIN', '.atbloom.de')
      axios.defaults.baseURL = 'https://drc-api.atbloom.de'
      break;

   /** DEVELOPMENT LOCAL */
   case 'development':
   default:
      app.provide('$_COOKIEDOMAIN', 'localhost')
      axios.defaults.baseURL = 'http://localhost:8000'
      break;
}

auth.me().then(() => {
   global.getOptions().then(() => {
      auth.loading = false
   })
})

import VueUploadComponent from 'vue-upload-component'
app.component('file-upload', VueUploadComponent)

app.directive('focus', {
   mounted(el, binding) { // When the bound element is inserted into the DOM...
      if (binding) el.focus() // Focus the element
   }
})

app.directive('click-outside', ClickOutside);

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import './assets/css/snackbar.css';
app.use(SnackbarService);
app.component("vue3-snackbar", Vue3Snackbar);

import { registerSVGComponents } from './plugins/svg-components'
registerSVGComponents(app)

import FloatingVue from 'floating-vue'
app.use(FloatingVue)


app.mount('#app')
