import { createRouter, createWebHistory } from "vue-router"

const routes = [
   {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component:() => import('../views/NotFound.vue'),
      meta: {
         authOnly: true
      }
   },
   {
      path: "/login",
      name: "login",
      component: () => import('../views/Login.vue'),
      meta: { guestOnly: true }
   },
   {
      path: "/logo",
      name: "logo",
      component: () => import('../views/Login.vue'),
      meta: { guestOnly: true }
   },
   {
      path: '/',
      name: 'adminpanel',
      component: () => import('../views/AdminPanel.vue'),
      meta: { authOnly: true },
      children: [
         {
            path: "/dashboard",
            name: "dashboard",
            component: () => import('../components/dashboard/Dashboard.vue'),
            meta: {  title: 'Dashboard', authOnly: true }
         },
         {       
            path: "/",
            name: "dashboard2",
            component: () => import('../components/dashboard/Dashboard.vue'),
            meta: { authOnly: true }
         },{
            path: "/cache",
            name: "cache",
            component: () => import('../components/user/Cache.vue'),
            meta: { authOnly: true }
         }
         ,{
            path: "/meinedaten",
            name: "meinedaten",
            component: () => import('../components/user/MeineDaten.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/meinehunde/:tab?",
            name: "meinehunde",
            component:  () => import('../components/user/MeineHunde.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/meinezucht/:tab?",
            name: "meine-zucht",
            component:  () => import('../components/user/MeineZucht.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/externepruefungmelden/:id",
            name: "externepruefungmelden",
            component:  () => import('../components/user/ExternePruefungMelden.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/externentitelmelden/:id",
            name: "externentitelmelden",
            component:  () => import('../components/user/ExternenTitelMelden.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/antragdrcchampionstitel/:id",
            name: "antragdrcchampionstitel",
            component: () => import('../components/user/AntragDRCChampionsTitel.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/antragzuchtzulassung/:id",
            name: "antragzuchtzulassung",
            component: () => import('../components/user/AntragZuchtzulassung.vue'),
            meta: {  title: 'Antrag für eine Zuchtzulassung', authOnly: true }
         },
         {
            path: "/antraguebernahme/:id",
            name: "antraguebernahme",
            component: () => import('../components/user/AntragUebernahme.vue'),
            meta: {  title: 'Antrag für die Übernahme eines Hundes', authOnly: true }
         },
         {
            path: "/antragatzweitschrift/:id",
            name: "antragatzweitschrift",
            component: () => import('../components/user/AntragATZweitschrift.vue'),
            meta: {  title: 'Antrag zur Ausstellung einer Zweitschrift der DRC-Ahnentafel eines Hundes', authOnly: true }
         },
         {
            path: "/antragleistungsheft/:id",
            name: "antragleistungsheft",
            component: () => import('../components/user/AntragLeistungsheft.vue'),
            meta: {  title: 'Antrag zur Ausstellung eines Leistungsheftes', authOnly: true }
         },
         {
            path: "/sterbedatumeintragen/:id",
            name: "sterbedatumeintragen",
            component: () => import('../components/user/SterbedatumEintragen.vue'),
            meta: {  title: 'Antrag', authOnly: true }
         },
         {
            path: "/miteigentuemermelden/:id",
            name: "miteigentuemermelden",
            component: () => import('../components/user/MiteigentuemerMelden.vue'),
            meta: {  title: 'Antrag', authOnly: true }
         },
         {
            path: "/hundabgabemelden/:id",
            name: "hundabgabemelden",
            component: () => import('../components/user/HundabgabeMelden.vue'),
            meta: {  title: 'Antrag', authOnly: true }
         },
         {
            path: "/hundefuehrerverwalten/:id",
            name: "hundefuehrerverwalten",
            component: () => import('../components/user/HundefuehrerVerwalten.vue'),
            meta: {  title: 'Hundeführer verwalten', authOnly: true }
         },
         {
            path: "/bezirksgruppenwechsel/:id",
            name: "bezirksgruppenwechsel",
            component: () => import('../components/user/Bezirksgruppenwechsel.vue'),
            meta: {  title: 'Bezirksgruppenwechsel', authOnly: true }
         },
         {
            path: "/mitgliedschaftkuendigen/:id",
            name: "mitgliedschaftkuendigen",
            component: () => import('../components/user/MitgliedschaftKuendigen.vue'),
            meta: {  title: 'Mitgliedschaft kündigen', authOnly: true }
         },
         {
            path: "/uploadneuzuechterseminar/:id",
            name: "uploadneuzuechterseminar",
            component: () => import('../components/user/UploadNeuzuechterseminar.vue'),
            meta: {  title: 'Upload Neuzüchterseminar', authOnly: true }
         },
         {
            path: "/antragzwingerschutz/:id",
            name: "antragzwingerschutz",
            component: () => import('../components/user/AntragZwingerschutz.vue'),
            meta: {  title: 'Antrag Zwingerschutz', authOnly: true }
         },
         {
            path: "/antragzuchtrasse/:id",
            name: "antragzuchtrasse",
            component: () => import('../components/user/AntragZuchtrasse.vue'),
            meta: {  title: 'Antrag Zuchtrasse', authOnly: true }
         },
         {
            path: "/antragparallelwurf/:id",
            name: "antragparallelwurf",
            component: () => import('../components/user/AntragParallelwurf.vue'),
            meta: {  title: 'Antrag Parallelwurf', authOnly: true }
         },
         {
            path: "/zwingergemeinschaftedit/:id",
            name: "zwingergemeinschaftedit",
            component: () => import('../components/user/ZwingergemeinschaftEdit.vue'),
            meta: {  title: 'Zwingergemeinschaft bearbeiten', authOnly: true }
         },
         {
            path: "/antragzuchtstaettenbesichtigung/:id",
            name: "antragzuchtstaettenbesichtigung",
            component: () => import('../components/user/AntragZuchtstaettenbesichtigung.vue'),
            meta: {  title: 'Antrag Zuchtstättenbesichtigung', authOnly: true }
         },
         {
            path: "/uploadgesundheit/:id",
            name: "uploadgesundheit",
            component: () => import('../components/user/UploadGesundheit.vue'),
            meta: {  title: 'Einreichung von Befunden und Belegen zur Gesundheit', authOnly: true }
         },
  
         // {
         //    path: "/antrag",
         //    name: "antrag",
         //    component: () => import('../components/user/Antrag.vue'),
         //    meta: {  title: 'Antrag ', authOnly: true }
         // },  
         {
            path: "/antraggentest/:id",
            name: "antraggentest",
            component: () => import('../components/user/AntragGentest.vue'),
            meta: {  title: 'Beauftragung eines DNA/Gentests', authOnly: true }
         },
         {
            path: "/antraghdedgutachten/:id",
            name: "antraghdedgutachten",
            component: () => import('../components/user/AntragHdEdGutachten.vue'),
            meta: {  title: 'Antrag HD/ED Gutachten', authOnly: true }
         },
         {
            path: "/anwartschaftmelden/:id",
            name: "anwartschaftmelden",
            component: () => import('../components/user/AnwartschaftMelden.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/leihhund",
            name: "leihhundeintrag",
            component: () => import('../components/user/Leihhund.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/leihhuendin",
            name: "leihhuendineintrag",
            component: () => import('../components/user/Leihhuendin.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/user/hundanlegen",
            name: "userhundanlegen",
            component: () => import('../components/user/AntragHundAnlegen.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hunde",
            name: "hundeindex",
            component: () => import('../components/hunde/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hunde/suche",
            name: "hundesuche",
            component: () => import('../components/hunde/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hunde/zuchthunde",
            name: "zuchthundeindex",
            component: () => import('../components/hunde/Zuchthunde.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hunde/drchunde",
            name: "drchundeindex",
            component: () => import('../components/hunde/DRCHunde.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hunde/deckrueden",
            name: "deckruedenindex",
            component: () => import('../components/hunde/Deckrueden.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/deckruedefreistellungcreate",
            name: "deckruedefreistellungcreate",
            component: () => import('../components/zuechter/DeckruedeFreistellungCreate.vue'),
            meta: { authOnly: true }
         },

         {
            path: "/hunde/welpen",
            name: "welpenindex",
            component: () => import('../components/hunde/Welpen.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hund/anlegen/:id",
            name: "hundanlegen",
            component: () => import('../components/hunde/HundAnlegen.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hund/init",
            name: "hundinit",
            component: () => import('../components/hunde/HundInit.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/hund/edit/:id/:tab?/:cache?",
            name: "hundeakte",
            component: () => import('../components/hunde/Hundeakte.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/hund/uebernahmeantrag/:id",
            name: "hunduebernahmeantrag",
            component: () => import('../components/hunde/AntragUebernahme.vue'),
            meta: { authOnly: true },
         },
         {
            // path: "/zwingerakte/:id/:tab?/:cache?",
            path: "/zwingerakte/:id/:tab?",
            name: "zwingerakte",
            component: () => import("../components/zwinger/Zwingerakte.vue"),
            meta: { authOnly: true },
         },
         { 
            path: "/wuerfe",
            name: "wuerfeindex",
            component: () => import('../components/wuerfe/Index.vue'),
            meta: { authOnly: true }
         },
         { 
            path: "/wuerfe/suche",
            name: "wuerfesuche",
            component: () => import('../components/wuerfe/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/wurfplancreate/:hund?",
            name: "wurfplancreate",
            component: () => import('../components/wuerfe/WurfplanCreate.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/wurfcreate/:hund?",
            name: "wurfcreate",
            // component: () => import('../components/wuerfe/WurfCreate.vue'),
            component: () => import('../components/wuerfe/WurfEdit.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/wurfedit/:id?/:cache?",
            name: "wurfedit",
            component: () => import('../components/wuerfe/WurfEdit.vue'),
            meta: { authOnly: true },
         },
         {
            path: "/mitglieder/vollmitglieder",
            name: "vollmitgliederindex",
            component: () => import('../components/mitglieder/Vollmitglieder.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/mitglieder/jugendmitglieder",
            name: "jugendmitgliederindex",
            component: () => import('../components/mitglieder/Jugendmitglieder.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/mitglieder/familienmitglieder",
            name: "familienmitgliederindex",
            component: () => import('../components/mitglieder/Familienmitglieder.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/personen",
            name: "personenindex",
            component:  () => import('../components/personen/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/richter",
            name: "richterindex",
            component: () => import('../components/richter/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltungcreate",
            name: "veranstaltungcreate",
            component: () => import('../components/veranstaltungen/VeranstaltungCreate.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltunganmeldung/:id?/:",
            name: "veranstaltunganmeldung",
            component: () => import('../components/veranstaltungen/Anmeldung.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltungedit/:id?/:cache?",
            name: "veranstaltungedit",
            component: () => import('../components/veranstaltungen/VeranstaltungCreate.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltungergebnis/:id?",
            name: "veranstaltungergebnis",
            component: () => import('../components/veranstaltungen/VeranstaltungErgebnis.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltungen",
            name: "veranstaltungenindex",
            component: () => import('../components/veranstaltungen/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/veranstaltungen/suche",
            name: "veranstaltungssuche",
            component: () => import('../components/veranstaltungen/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zuchtstaettenbesichtigungcreate",
            name: "zuchtstaettenbesichtigungcreate",
            component:    () => import('../components/zwinger/helper/ZuchtstaettenbesichtigungCreate.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zwinger/suche",
            name: "zwingersuche",
            component: () => import('../components/zwinger/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zwinger",
            name: "zwingerindex",
            component: () => import('../components/zwinger/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zuechter",
            name: "zuechterindex",
            component: () => import('../components/zuechter/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zuchtwarte",
            name: "zuchtwartindex",
            component: () => import('../components/zuchtwarte/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/zuchtwarte/suche",
            name: "zuchtwartesuche",
            component: () => import('../components/zuchtwarte/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/aerzte/suche",
            name: "aerztesuche",
            component: () => import('../components/aerzte/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/richter/suche",
            name: "richtersuche",
            component: () => import('../components/richter/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/sonderleiter",
            name: "sonderleiterindex",
            component: () => import('../components/sonderleiter/Index.vue'),
            meta: { authOnly: true }
         },        
         {
            path: "/profil",
            name: "profil",
            component: () => import('../components/user/ProfilEdit.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/kontaktedit",
            name: "kontaktedit",
            component: () => import('../components/user/KontaktEdit.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/bankverbindungnedit",
            name: "bankverbindungedit",
            component: () => import('../components/user/BankverbindungEdit.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/mitgliedschaftedit",
            name: "mitgliedschaftedit",
            component: () => import('../components/user/MitgliedschaftEdit.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/mitglieder",
            name: "mitgliederindex",
            component: () => import('../components/mitglieder/Index.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/personen/suche",
            name: "personensuche",
            component: () => import('../components/personen/Suche.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/person/personenakte/:id",
            name: "personenakte",
            component: () => import('../components/personen/Personenakte.vue'),
            meta: { authOnly: true }
         },
         {
            path: "/mitglieder/landesbezirksgruppen",
            name: "landesbezirksgruppen",
            component: () => import('../components/landesbezirksgruppen/Suche.vue'),
            meta: { authOnly: true }
         }
      ]
   },
]

const router = createRouter({
   history: createWebHistory(),
   routes, 
   // scrollBehavior(to, from, savedPosition) {
   //       return { top: 0 }
   //  },
})

function isLoggedIn() {
   return getCookie('drc');
   return localStorage.getItem("drc");
}

function getCookie(cname) {
   let name = cname + "=";
   let decodedCookie = decodeURIComponent(document.cookie);
   let ca = decodedCookie.split(';');
   for(let i = 0; i <ca.length; i++) {
     let c = ca[i];
     while (c.charAt(0) == ' ') {
       c = c.substring(1);
     }
     if (c.indexOf(name) == 0) {
       return c.substring(name.length, c.length);
     }
   }
   return false;
 }

router.beforeEach((to, from, next) => {
   

   if (to.matched.some(record => record.meta.authOnly)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!isLoggedIn()) {
         next({
            name: "login",
            query: { redirect: to.fullPath }
         });
      } else {
         next();
      }
   } else if (to.matched.some(record => record.meta.guestOnly)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (isLoggedIn()) {
         next({
            name: "dashboard",
            query: { redirect: to.fullPath }
         });
      } else {
         next();
      }
   } else {
      next(); // make sure to always call next()!
   }
});

// router.afterEach(to => {
//    localStorage.setItem(LS_ROUTE_KEY, to.name)
//  })

export default router
